import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Download, Calendar } from 'lucide-react';
import InstagramEmbed from './InstagramEmbed';
import { Helmet } from 'react-helmet';

interface DiabetesQuestionProps {
  question: string;
  answer: string[];
}

const DiabetesInfoPage: React.FC = () => {
  const diabetesQuestions: DiabetesQuestionProps[] = [
    {
      question: "How can Type 1 Diabetes present?",
      answer: [
        "Classic triad: Polyuria, Polydipsia, Weight loss",
        "General symptoms: Malaise, Weakness, Loss of energy",
        "Other symptoms: Headache, Pain abdomen, Irritability",
        "Additional signs: Bed wetting, Blurring of vision, Leg cramps",
        "Potential issues: Infections, Genital itching (in girls)",
        "Severe cases: Altered consciousness/Coma"
      ]
    },
    {
      question: "How can Type 1 Diabetes be diagnosed?",
      answer: [
        "Diabetes symptoms + Random Plasma Glucose ≥ 200mg/dl",
        "Fasting Blood Glucose > 126mg/dl",
        "Plasma glucose ≥ 200mg/dl at 2 hours during OGTT"
      ]
    },
    {
      question: "What are key factors in Type 1 Diabetes management?",
      answer: [
        "Regular Blood Glucose monitoring",
        "Insulin administration",
        "Lifestyle management (Diet & Exercise)"
      ]
    },
    {
      question: "What are Treatment objectives of Diabetes management?",
      answer: [
        "Eliminate diabetic symptoms",
        "Prevent acute complications: DKA & hypoglycemia",
        "Target HbA1C < 8%",
        "Blood Glucose targets:",
        "- Fasting: 80-120mg/dl",
        "- Before meal: 100-140mg/dl",
        "- After meals: 100-200mg/dl",
        "Ensure normal growth & puberty",
        "Early detection of associated diseases",
        "Prevent chronic vascular complications"
      ]
    },
    {
      question: "How many times should Blood Sugar be checked daily?",
      answer: [
        "Ideally 5 times/day:",
        "- Morning: Before breakfast (fasting)",
        "- Afternoon: Before lunch",
        "- Evening: Before dinner",
        "- 2.5-3 hrs post dinner",
        "- Late night: 3 am",
        "Essential to check BG and give Insulin before 3 major meals",
        "Ensure BG > 100 mg/dl before child sleeps"
      ]
    },
    {
      question: "Can Type 1 Diabetes kids eat all kinds of foodstuffs?",
      answer: [
        "Diet based on individual taste, similar to non-diabetics",
        "Avoid simple sugars: table sugar, honey, candy, sweets, fruit juices",
        "Eat balanced meals with vegetables, whole grains, and pulses",
        "Maintain consistency in meal timing and quantity",
        "Time meals with insulin schedule",
        "Divide total caloric intake into 3 meals and 2-3 mid-meal snacks",
        "'Free Foods' (carbs < 5g) include: lemonade, buttermilk, paneer, tofu, egg white, salad, soups, popcorn, roasted chana, nuts"
      ]
    },
    {
      question: "Can Type 1 Diabetes kids participate in physical activities?",
      answer: [
        "Should exercise and participate in all sports regularly",
        "Benefits: sense of well-being",
        "Improves glycemic control",
        "Enhances cardiovascular risk factors",
        "Helps maintain desirable body weight"
      ]
    },
    {
      question: "What to do if child has low Blood Sugar?",
      answer: [
        "Give 1/2 - 1 teaspoon sugar (depends on age)",
        "Recheck BG after 20 mins",
        "Alternatives: honey, glucose water, 2-3 sips coke/juice",
        "DO NOT use chocolates/biscuits/toffees (contain fat)",
        "Glucagon injection for hypoglycemic emergencies"
      ]
    },
    {
      question: "What to do if child has high Blood Sugar?",
      answer: [
        "Increase insulin dosage",
        "Change insulin vial",
        "Check urine ketones",
        "Ensure child drinks more water",
        "Contact doctor"
      ]
    }
  ];

  const [openQuestion, setOpenQuestion] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

   

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = '/diabetes-patient-info.pdf';
    link.download = 'diabetes-info.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
    <Helmet>
        <title>Child & Teen Diabetes Care | Type 1 Diabetes Treatment Guide</title>
        <meta name="description" content="Complete guide for children with Type 1 Diabetes: symptoms, blood sugar management, diet tips, and emergency care. Learn about insulin treatment, healthy eating, and helping your child live a normal life with diabetes." />
        
        {/* Keywords targeting parents searching for symptoms and care */}
        <meta name="keywords" content="child diabetes symptoms, type 1 diabetes in children, juvenile diabetes, kid always thirsty and peeing, child losing weight diabetes, diabetes care for kids, insulin for children, managing child blood sugar, diabetes diet for kids, child diabetes emergency" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Child & Teen Diabetes Care | Expert Treatment Guide" />
        <meta property="og:description" content="Expert guide for parents of children with Type 1 Diabetes. Learn about symptoms, daily care, diet, and emergency management. Help your child thrive with diabetes." />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Child Diabetes Care & Treatment Guide" />
        <meta name="twitter:description" content="Complete guide for parents: managing child diabetes, blood sugar testing, insulin treatment, diet tips, and emergency care." />

        {/* Additional meta tags for specific symptoms and concerns */}
        <meta name="symptoms" content="excessive thirst, frequent urination, unexplained weight loss, tiredness, blurry vision, bed wetting" />
        <meta name="treatment" content="insulin therapy, blood sugar testing, diabetes diet plan, exercise guidelines" />
        <meta name="emergency" content="low blood sugar treatment, high blood sugar management, diabetes emergency care" />
        
        {/* Location and specialty tags */}
        <meta name="specialty" content="child diabetes specialist, pediatric endocrinologist, juvenile diabetes doctor" />
      </Helmet>

    <div className="container mx-auto px-4 py-8 max-w-4xl mt-48">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-fraunces-slab font-bold mb-8 text-center">
        Type 1 Diabetes: Patient Information
      </h1>
      <div className="space-y-4">
        {diabetesQuestions.map((item, index) => (
          <div
            key={index}
            className="border rounded-lg overflow-hidden shadow-md"
          >
            <div
              onClick={() => toggleQuestion(index)}
              className="flex justify-between items-center p-3 sm:p-4 bg-gray-100/50 hover:bg-gray-200 cursor-pointer transition-colors"
            >
              <h2 className="text-base sm:text-xl font-semibold">{item.question}</h2>
              {openQuestion === index ? <ChevronUp /> : <ChevronDown />}
            </div>
            {openQuestion === index && (
              <div className="p-3 sm:p-4 bg-white">
                {item.answer.map((line, lineIndex) => (
                  <p key={lineIndex} className="mb-2 text-sm sm:text-base">{line}</p>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-8 space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row justify-center items-center">
        <button 
          onClick={handleDownload}
          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors w-full sm:w-auto"
        >
          <Download className="mr-2" size={20} />
          Download Patient Guide
        </button>
        <button 
          onClick={()=>  window.location.href = "/contact"}
          className="flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors w-full sm:w-auto"
        >
          <Calendar className="mr-2" size={20} />
          Book Appointment
        </button>
      </div>

      <div className="mt-6 sm:mt-8 text-center">
        <p className="text-xs sm:text-sm text-gray-600 italic">
          Consult Dr. Swati Kanodia for personalized medical advice.
        </p>
        <div className="mt-4 text-sm text-gray-700">
          <p>Contact: +91 9015550000</p>
          <p>Email: dr.swatikanodia@gmail.com</p>
        </div>
      </div>

      <InstagramEmbed url='https://www.instagram.com/p/DCWI7qTPxCT/' />

      
    </div>
    </>
  );
};

export default DiabetesInfoPage;