import React from 'react';
import { motion } from 'framer-motion';
import ScatteredBackground from '../ui/ScatteredBackground';
import { useNavigate } from 'react-router-dom';

const HomeAbout: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const navigate = useNavigate();

  return (
    <ScatteredBackground darkColor="#FFDB58" lightColor="#FAF8EB">
      <motion.section
        id="home-about"
        className="relative py-16 overflow-hidden"
        initial="hidden"
        animate="visible"
        variants={containerVariants}>

        <div className="container relative z-10 mx-auto px-4">
          <motion.h2
            className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 md:mb-16 text-yellow-800 font-fraunces-slab text-center"
            variants={itemVariants}
          >
            Meet Dr. Swati Kanodia
          </motion.h2>
          <div className="flex flex-col md:flex-row items-center justify-between">
            <motion.div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center md:justify-start" variants={itemVariants}>
              <img
                src="/images/image1.jpg"
                alt="Dr. Swati Kanodia"
                className="rounded-lg shadow-xl max-w-[80%] h-auto"
              />
            </motion.div>
            <motion.div className="w-full md:w-1/2 md:pl-8 text-center md:text-left" variants={itemVariants}>
              <h3 className="text-2xl font-bold text-yellow-700 mb-4 font-work-sans">
                Consultant - Pediatric & Adolescent Endocrinologist
              </h3>
              <p className="text-md md:text-xl text-gray-800 mb-4 font-pt-serif-regular">
                Dr. Swati Kanodia is a highly skilled pediatric endocrinologist with over 10 years of experience in pediatrics and 7 years of specialized practice in pediatric & adolescent endocrinology and diabetes.
              </p>
              <p className="text-md md:text-xl text-gray-800 mb-4 font-pt-serif-regular">
                With her extensive training and expertise, Dr. Kanodia provides comprehensive care for a wide range of pediatric endocrine disorders, including:
              </p>
              <ul className="list-disc text-sm md:text-lg list-inside text-gray-800 mb-6 font-pt-serif-regular mx-auto md:mx-0 max-w-[80%] md:max-w-none">
                <li>Gestational Diabetes</li>
                <li>Diabetes (Type 1, Type 2, Neonatal, Monogenic)</li>
                <li>Growth problems (short stature/tall stature)</li>
                <li>Obesity</li>
                <li>Pubertal issues (early or delayed puberty)</li>
                <li>Thyroid disorders</li>
                <li>Ambiguous genitalia</li>
                <li>Pediatric bone diseases</li>
              </ul>

            </motion.div>
          </div>
          
          <div className='flex justify-center mt-8'>
          <motion.button
            className="bg-yellow-600 text-white font-work-sans px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-yellow-700 hover:scale-105"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/about')}
          >
            Learn More About Dr. Kanodia
          </motion.button>
          </div>
        </div>
      </motion.section>
    </ScatteredBackground>
  );
};

export default HomeAbout;