import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import treatmentsData, { TreatmentData } from "../components/data/treatments";
import AppointmentModal from "../components/utilities/AppointmentModal";
import { Calendar, Video } from "lucide-react";
import ScatteredBackground from "../components/ui/ScatteredBackground";

interface TreatmentCardProps {
    treatment: TreatmentData;
}

const TreatmentCard: React.FC<TreatmentCardProps> = ({ treatment }) => {
    return (
        <Link to={`/treatments/${treatment.slug}`} className="block">
            <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-105">
                <img src={treatment.image} alt={treatment.title} className="w-full h-56 object-cover" />
                <div className="p-6">
                    <h3 className="font-fraunces-slab text-2xl font-semibold text-foreground mb-3">{treatment.title}</h3>
                    <p className="font-work-sans text-base text-pink-600">{treatment.description}</p>
                </div>
            </div>
        </Link>
    );
};

const Treatments: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Child Hormone & Growth Treatments in Delhi | Dr. Swati Kanodia</title>
                <meta name="description" content="Expert treatment for children's growth issues, diabetes, thyroid problems, early/late puberty, and weight management in Delhi. Specialized care with both in-person and online consultation options." />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Child Growth & Hormone Treatments - Dr. Swati Kanodia" />
                <meta property="og:description" content="Get specialized treatment for your child's growth, diabetes, thyroid, and hormone-related issues. Trusted care in Delhi with online consultation available." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="/images/treatments/treatments-hero.jpg" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Child Growth & Development Treatments - Dr. Swati Kanodia" />
                <meta name="twitter:description" content="Complete treatment for child growth problems, diabetes, thyroid issues, and hormone imbalances. Expert care in Delhi with virtual consultation options." />
                <meta name="twitter:image" content="/images/treatments/treatments-hero.jpg" />

                {/* Treatment-specific Keywords */}
                <meta name="keywords" content="child growth treatment, kids height problems, child diabetes care, thyroid treatment for children, early puberty treatment, delayed growth treatment, childhood obesity treatment, hormone problem in children, online child doctor delhi" />

                {/* Additional SEO Tags */}
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Dr. Swati Kanodia" />

                {/* Treatment Categories */}
                <meta name="treatment-categories" content="Growth Problems, Diabetes, Thyroid Issues, Puberty Concerns, Weight Management" />

                {/* Schema.org markup for medical treatments */}
                <script type="application/ld+json">
                    {`
            {
                "@context": "https://schema.org",
                "@type": "MedicalTherapy",
                "name": "Child Growth & Hormone Treatments - Dr. Swati Kanodia",
                "description": "Comprehensive treatment for children's growth and hormone-related conditions",
                "medicalSpecialty": "Pediatric Endocrinology",
                "relevantSpecialty": {
                    "@type": "MedicalSpecialty",
                    "name": "Child Growth & Hormone Specialist"
                },
                "availableService": [
                    {
                        "@type": "MedicalProcedure",
                        "name": "Growth Problem Treatment",
                        "description": "Treatment for children not growing at normal rate"
                    },
                    {
                        "@type": "MedicalProcedure",
                        "name": "Child Diabetes Care",
                        "description": "Management and treatment of diabetes in children"
                    },
                    {
                        "@type": "MedicalProcedure",
                        "name": "Thyroid Treatment",
                        "description": "Treatment for overactive or underactive thyroid in children"
                    },
                    {
                        "@type": "MedicalProcedure",
                        "name": "Puberty Issues Treatment",
                        "description": "Treatment for early or delayed puberty"
                    },
                    {
                        "@type": "MedicalProcedure",
                        "name": "Weight Management",
                        "description": "Treatment for childhood obesity and weight issues"
                    }
                ],
                "offers": {
                    "@type": "Offer",
                    "availability": "InStock",
                    "availabilityStarts": "2024-02-03",
                    "category": "Medical Treatment"
                }
            }
        `}
                </script>
            </Helmet>
            <ScatteredBackground darkColor="#FFF0F5" lightColor="#f5e6f1">

                <main className="flex-grow">
                    <section className="relative w-full h-screen max-h-[900px] mt-28 md:mt-50 overflow-hidden">
                        <img
                            src="/images/treatments/treatments-hero.jpg"
                            alt="Endocrinology Treatments"
                            className="absolute w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40 flex flex-col justify-center items-center text-center px-4 sm:px-8 md:px-12 lg:px-16">
                            <div className="max-w-4xl mx-auto">
                                <h1 className="font-fraunces-slab text-4xl sm:text-5xl md:text-6xl lg:text-7xl mb-6 text-white">
                                    <span className="text-yellow-300">Expert Endocrine Care</span>
                                </h1>
                                <p className="font-work-sans text-xl md:text-2xl text-white mb-8">
                                    Personalized treatments for optimal hormonal health
                                </p>
                                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                                    <button
                                        onClick={openModal}
                                        className="bg-purple-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-purple-700 hover:scale-105 shadow-lg"
                                    >
                                        Book Appointment
                                        <Calendar className="ml-3 w-5 h-5" />
                                    </button>
                                    <button
                                        onClick={openModal}
                                        className="bg-green-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-green-700 hover:scale-105 shadow-lg"
                                    >
                                        Virtual Consultation
                                        <Video className="ml-3 w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
                    </section>

                    <section className="mx-auto px-4 md:px-14 py-16">
                        <h2 className="text-4xl font-bold mb-12 text-center text-foreground font-fraunces-slab lg:text-6xl">Our Treatments</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
                            {treatmentsData.map((treatment, index) => (
                                <TreatmentCard key={index} treatment={treatment} />
                            ))}
                        </div>
                    </section>
                </main>
            </ScatteredBackground>
        </>
    );
};

export default Treatments;
