import React, { useState } from 'react';
import { blogPosts } from '../components/data/blogs';
import { Link } from 'react-router-dom';
import { ArrowRight, Calendar, Mail } from 'lucide-react';
import BlogCard from '../components/utilities/BlogCard';
import AppointmentModal from '../components/utilities/AppointmentModal';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

// Hard-coded Doctor's Recommendations
const doctorsRecommendations = [
  {
    title: "Diet Handout",
    excerpt: "Healthy Diet Guidelines for Children",
    image: "/images/image.png",
    link: "/diet-handout",
    tags: ["Nutrition", "Pediatrics", "Health"]
  },
  {
    title: "Gestational Diabetes",
    excerpt: "A pregnancy-related condition where blood sugar levels rise due to hormonal changes, potentially affecting both mother and baby's health. Proper management through diet, exercise, and medical monitoring is crucial.",
    image: "/images/treatments/diabetes.jpeg",
    link: "/gestational-info",
    tags: ["Pregnancy Health", "Metabolic Disorders", "Maternal Care", "Diabetes Prevention"]
  },
  {
    title: "Diabetes Management",
    excerpt: "Comprehensive Guide for Diabetic Patients",
    image: "/images/treatments/diabetes-mellitus.jpg",
    link: "/diabetes-info",
    tags: ["Diabetes", "Treatment", "Adolescent Health"]
  },
  {
    title: "Multiple Pituitary Hormone Deficiency (MPHD)",
    excerpt: "A condition affecting the body's hormone production, impacting growth, development, and metabolism. Early diagnosis and hormone replacement therapy can help manage symptoms and ensure proper development in children.",
    image: "/images/mphd.png",
    link: "/mphd",
    tags: ["Endocrinology", "Pediatric Health", "Hormone Disorders", "Growth Development"]

  }
];

const PatientEducation: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
     <Helmet>
        <title>Dr. Swati Kanodia - Child Growth & Development Specialist | Pediatric Endocrinologist</title>
        <meta name="description" content="Get expert advice on child growth problems, diabetes in children, hormone disorders, and teenage health issues. Dr. Swati Kanodia provides specialized care for kids' growth and development concerns." />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Child Growth Doctor & Hormone Specialist - Dr. Swati Kanodia" />
        <meta property="og:description" content="Expert treatment for children's growth issues, diabetes, thyroid problems, and puberty concerns. Easy-to-understand medical advice and guidance for parents." />
        <meta property="og:type" content="website" />
        
        {/* Keywords */}
        <meta name="keywords" content="child growth doctor, kids hormone specialist, pediatric diabetes, growth problems in children, teenage health specialist, child development doctor, hormone treatment for children, Mumbai child specialist" />
        
        {/* Additional SEO Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Dr. Swati Kanodia" />
        
        {/* Additional Topic Tags */}
        <meta name="topic" content="Child Growth and Development" />
        <meta name="topic" content="Pediatric Diabetes Care" />
        <meta name="topic" content="Teenage Health Issues" />
        <meta name="topic" content="Kids Hormone Treatment" />
      </Helmet>
    <main className='flex-grow'>
      {/* Hero Section - Unchanged */}
      <section className="relative mt-28 md:mt-48 w-full h-screen max-h-screen overflow-hidden">
        <img
          src="/images/blogs/blog-hero.jpg"
          alt="Patient Education"
          className="absolute w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40 flex flex-col justify-center items-center text-center px-4 sm:px-8 md:px-12 lg:px-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="max-w-4xl mx-auto mt-24"
          >
            <h1 className="font-fraunces-slab text-4xl sm:text-5xl md:text-6xl lg:text-7xl mb-4 text-white">
              Research & Insights
            </h1>
            <p className="font-work-sans text-lg md:text-xl lg:text-2xl text-yellow-300 mb-6">
              Pediatric & Adolescent Endocrinology
            </p>
            <p className='font-pt-serif-regular text-lg md:text-xl lg:text-2xl text-yellow-300 mb-6'>
              offered by
            </p>
            <p className="text-white text-xl md:text-2xl font-bold mb-12">
              Dr. Swati Kanodia
            </p>

            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={openModal}
                className="bg-purple-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-purple-700 hover:scale-105 shadow-lg"
              >
                Get Consultation
                <Calendar className="ml-3 w-5 h-5" />
              </button>
              <a
                href="mailto:dr.swatikanodia@gmail.com"
                className="bg-green-600 text-white font-pt-serif-regular px-6 py-3 rounded-lg flex items-center justify-center text-lg transition-all duration-300 hover:bg-green-700 hover:scale-105 shadow-lg"
              >
                Contact Email
                <Mail className="ml-3 w-5 h-5" />
              </a>
            </div>
          </motion.div>
        </div>
        <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
      </section>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-8 mt-28 md:mt-30">
        {/* Doctor's Recommendations Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-fraunces-slab font-semibold mb-8 text-center">
            Doctor's Recommendations
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {doctorsRecommendations.map((recommendation, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-all duration-300"
              >
                <img
                  src={recommendation.image}
                  alt={recommendation.title}
                  className="w-full h-48 object-contain"
                />
                <div className="p-4">
                  <h3 className="font-fraunces-slab font-bold text-xl mb-2">
                    {recommendation.title}
                  </h3>
                  <p className="text-gray-600 text-sm mb-4 line-clamp-3">
                    {recommendation.excerpt}
                  </p>
                  <div className="flex flex-wrap gap-2 mb-4">
                    {recommendation.tags.map((tag, tagIndex) => (
                      <span
                        key={tagIndex}
                        className="text-xs bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                  <Link
                    to={recommendation.link}
                    className="inline-flex items-center text-yellow-800 hover:text-yellow-600 transition-colors"
                  >
                    Read More <ArrowRight className="ml-2 h-4 w-4" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Educational Content Section */}
        <section>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2 space-y-8">
              <YouTubeEmbed />
            </div>

            <div className="space-y-8">
              <h2 className="text-2xl font-fraunces-slab font-semibold mb-4">Latest Articles</h2>
              {blogPosts.map((post) => (
                <BlogCard key={post.slug} post={post} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </main>
    </>
  );
};

const YouTubeEmbed: React.FC = () => (
  <div className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
    <div className="aspect-video">
      <iframe
        src="https://www.youtube.com/embed/0MJWqAOZ8ZE"
        title='Feature'
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="w-full h-full"
      ></iframe>
    </div>
    <div className="p-6">
      <h3 className="font-fraunces-slab font-bold text-lg mb-2">Featured Video</h3>
      <p className="text-gray-600 text-sm mb-4">Watch our latest educational video on pediatric endocrinology.</p>
      <Link to="/gallery" className="inline-flex items-center bg-yellow-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition-colors duration-300">
        Explore All Educational Videos <ArrowRight className="ml-2 h-4 w-4" />
      </Link>
    </div>
  </div>
);

export default PatientEducation;