import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronDown } from 'lucide-react';
import TextCarousel from '../utilities/TextCarousel';
import AppointmentModal from '../utilities/AppointmentModal';
import treatmentsData from '../data/treatments';

interface NavItem {
  name: string;
  target: string;
  subCategories?: Array<{
    id: string;
    title: string;
  }>;
}

interface TextItem {
  text: string;
  link: string;
}

const navItems: NavItem[] = [
  { name: "Home", target: "" },
  { name: "About", target: "about" },
  { name: "Specialty Services", target: "services" },
  {
    name: "Conditions Treated",
    target: "treatments",
    subCategories: treatmentsData.map(item => ({
      id: item.slug,
      title: item.title,
    }))
  },
  { name: "Patient Education", target: "patient-education" },
  { name: "Gallery", target: "gallery" },
];

const texts: TextItem[] = [
  { text: "Expert Paediatric Endocrinologist", link: "" },
  { text: " 901-555-0000 ", link: "tel:9015550000" },
  { text: "Thank you for contacting Dr Swati Kanodia Kindly message/ call for consultation/appointment from Monday- Saturday, between 11am-7pm. Kindly call/ message on Sunday only in case of emergency (additional charges may apply).", link: '' },
  { text: "dr.swatikanodia@gmail.com", link: "mailto:dr.swatikanodia@gmail.com" },
  { text: " Diabetes & Obesity Expert", link: "" },
];

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  


  useEffect(() => {
    const handleScroll = () => {
     
      setIsScrolled(window.scrollY > 50);
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
      if (window.innerWidth >= 1024) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setActiveSubmenu(null);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setActiveSubmenu(null);
  };

  const handleSubmenuToggle = (itemName: string, event: React.MouseEvent) => {
    if (isMobile) {
      event.preventDefault();
      setActiveSubmenu(prevSubmenu => prevSubmenu === itemName ? null : itemName);
    }
  };

  const renderNavItem = (item: NavItem, isMobile: boolean = false) => (
    <div
      key={item.target}
      className={`relative ${isMobile ? 'py-2' : 'group'}`}
      onMouseEnter={() => !isMobile && item.subCategories && setActiveSubmenu(item.name)}
      onMouseLeave={() => !isMobile && setActiveSubmenu(null)}
    >
      <Link
        to={`/${item.target}`}
        className={`text-black hover:text-green-700 transition-colors text-base md:text-lg flex items-center justify-between ${isMobile ? 'w-full' : ''}`}
        onClick={() => {
          if (isMobile && !item.subCategories) closeMenu();
        }}
      >
        {item.name}
        {item.subCategories && (
          <ChevronDown
            className={`ml-1 transition-transform duration-300 ${activeSubmenu === item.name ? 'rotate-180' : ''
              }`}
            size={20}
            onClick={(e: React.MouseEvent) => handleSubmenuToggle(item.name, e)}
          />
        )}
      </Link>
      {item.subCategories && (
        <div
          className={`
            ${isMobile ? 'mt-2 pl-4' : 'absolute left-0 w-64 bg-white rounded-md shadow-lg z-20 transition-all duration-300'}
            ${isMobile ? (activeSubmenu === item.name ? 'block' : 'hidden') : 'opacity-0 invisible group-hover:opacity-100 group-hover:visible'}
          `}
        >
          {item.subCategories.map((subItem) => (
            <Link
              key={subItem.id}
              to={`/${item.target}/${subItem.id}`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-200"
              onClick={() => {
                if (isMobile) closeMenu();
              }}
            >
              {subItem.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <header className='fixed top-0 left-0 right-0 z-[99] bg-white w-full'>
      <div className={`bg-yellow-600 ${isScrolled ? 'hidden' : 'block'}`}>
        <TextCarousel items={texts} speed={20} />
      </div>


      <nav className="container mx-auto px-4 py-4 flex justify-between items-center relative">
        {/* Menu button - positioned left */}
        <div className="lg:hidden z-10">
          <button
            onClick={toggleMenu}
            className="focus:outline-none transition-transform duration-300 ease-in-out"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>

        {/* Mobile logo - centered */}
        <div className="absolute left-1/2 transform -translate-x-1/2 lg:hidden">
          <Link to="/" className="block">
            <img
              src="/images/logo.png"
              className="h-28 w-44 object-contain"
              alt="Dr. Swati Logo"
            />
          </Link>
        </div>

        {/* Desktop logo - left aligned */}
        <Link to="/" className="flex-shrink-0 hidden lg:block">
          <img
            src="/images/logo.jpeg"
            className="h-32 w-44 object-cover"
            alt="Dr. Swati Logo"
          />
        </Link>

        {/* Desktop menu items */}
        <div className="hidden lg:flex lg:items-center lg:justify-center space-x-8">
          {navItems.map(item => renderNavItem(item))}
        </div>

        {/* Contact button */}
        <div className="hidden lg:block">
          <Link
            className="mt-2 w-full bg-white hover:bg-green-700 text-green-800 hover:text-white font-bold py-2 px-12 rounded-lg border-2 border-green-700 text-lg transition duration-300 ease-in-out"
            to="/contact"
          >
            Contact
          </Link>
        </div>
      </nav>

      {/* Mobile menu */}
      <div
        className={`lg:hidden fixed inset-0 bg-white z-50 transition-transform duration-300 ease-in-out ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        <div className="h-full overflow-y-auto">
          <div className="p-6">
            <button
              onClick={closeMenu}
              className="absolute top-4 right-4 focus:outline-none"
            >
              <X size={24} />
            </button>
            <div className="mt-12 space-y-4">
              {navItems.map(item => renderNavItem(item, true))}
            </div>
            <div className="mt-6 flex justify-center">
              <Link
                className="w-full max-w-xs bg-white hover:bg-green-700 text-green-800 hover:text-white font-bold py-3 px-6 rounded-lg border-2 border-green-700 text-lg transition duration-300 ease-in-out text-center"
                to="/contact"
                onClick={closeMenu}
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>

      <AppointmentModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </header>
  );
};

export default Header;