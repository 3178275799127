import React, { useState, useRef, useEffect } from "react";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import VimeoEmbed from "../components/utilities/VimeoEmbed";
import { FaYoutube, FaFacebookSquare } from "react-icons/fa";
import ScatteredBackground from "../components/ui/ScatteredBackground";
import { Helmet } from "react-helmet";

type FBContentItem =
  | { type: 'image'; src: string }
  | { type: 'video'; id: string };


export const YouTubeVideo = ({ videoId, autoplay = false, controls = true, mute = false }: { videoId: string, autoplay?: boolean, controls?: boolean, mute?: boolean }) => {
  const src = `https://www.youtube.com/embed/${videoId}?autoplay=${autoplay ? 1 : 0}&controls=${controls ? 1 : 0}&mute=${mute ? 1 : 0}`;

  return (
    <div className="aspect-video rounded-lg overflow-hidden shadow-lg">
      <iframe
        title="Youtube"
        src={src}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="w-full h-full"
      ></iframe>
    </div>
  );
};

const Gallery: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const playerRef = useRef<HTMLIFrameElement>(null);

  console.log(isPlaying,setIsPlaying ,isMuted, setIsMuted)

  const videoIds = [
    "vG8GNgzCQd8",
    "D1DFypEBp-c",
    "sBdk9vHxzaU",
    "WhnqFulxn6I"
  ];

  const fbContent: FBContentItem[] = [
    { type: 'image', src: "/images/image1.jpg" },
    { type: 'image', src: "/images/image2.jpg" },
    { type: 'image', src: "/images/image3.jpg" },
    { type: 'video', id: "1013880909" },
    { type: 'video', id: "1013881415" },
  ];

  useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;
      player.contentWindow?.postMessage(JSON.stringify({
        event: 'command',
        func: isPlaying ? 'playVideo' : 'pauseVideo'
      }), '*');
      player.contentWindow?.postMessage(JSON.stringify({
        event: 'command',
        func: isMuted ? 'mute' : 'unMute'
      }), '*');
    }
  }, [isPlaying, isMuted]);

  return (
    <>
      <Helmet>
        <title>Educational Videos on Child Growth & Health | Dr. Swati Kanodia's Video Library</title>
        <meta name="description" content="Watch easy-to-understand videos about child growth, diabetes in kids, hormone problems, and teenage health. Dr. Swati Kanodia explains medical topics in simple terms for parents." />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Watch Child Health Videos - Dr. Swati Kanodia's Medical Guidance" />
        <meta property="og:description" content="Free educational videos explaining children's growth issues, diabetes, and hormone problems. Expert medical advice in simple language for parents." />
        <meta property="og:type" content="website" />

        {/* Video-specific meta tags */}
        <meta property="og:video:type" content="text/html" />
        <meta property="og:video:width" content="1280" />
        <meta property="og:video:height" content="720" />

        {/* Keywords */}
        <meta name="keywords" content="child health videos, kids growth doctor videos, pediatric diabetes education, hormone problems in children videos, medical education for parents, child development videos, Dr Swati Kanodia youtube, kids health education" />

        {/* Additional SEO Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Dr. Swati Kanodia" />

        {/* Content Type Tags */}
        <meta name="content-type" content="Educational Videos" />
        <meta name="content-category" content="Medical Education" />
        <meta name="target-audience" content="Parents, Caregivers" />

        {/* Topic Tags */}
        <meta name="topic" content="Child Growth Videos" />
        <meta name="topic" content="Kids Health Education" />
        <meta name="topic" content="Medical Guidance for Parents" />
        <meta name="topic" content="Child Development Resources" />
      </Helmet>
      <main className="flex-grow">
        <ScatteredBackground lightColor="#fff" darkColor="#d6d4d6">

          <motion.section
            id="hero"
            className="w-full max-w-7xl mx-auto px-4 py-8 sm:py-12 mt-24 sm:mt-36 mb-8 sm:mb-10 md:mb-20"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="relative rounded-xl overflow-hidden shadow-2xl">
              <YouTubeVideo
                videoId="lscI-Ml-O3A"
                autoplay={true}
                controls={false}
                mute={false}
              />
              <div className="absolute top-4 left-4 bg-white bg-opacity-75 rounded-full p-2 flex items-center space-x-2">

                <span className="text-sm font-medium">📍 Pinned Video</span>
              </div>
            </div>
          </motion.section>


          <motion.section
            id="fbContent"
            className="w-full max-w-7xl mx-auto mb-8 sm:mb-10 md:mb-20"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3 className="text-2xl sm:text-3xl md:text-4xl font-semibold mb-4 sm:mb-6 text-foreground font-fraunces-slab px-4">Featured Content</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 px-4">
              {fbContent.map((item, index) => (
                <motion.div
                  key={index}
                  className="aspect-[9/16] rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105"
                  whileHover={{ y: -5 }}
                >
                  {item.type === 'image' ? (
                    <img src={item.src} alt={`Featured content ${index + 1}`} className="w-full h-full object-cover" />
                  ) : (
                    <VimeoEmbed
                      videoId={item.id}
                      title={`Featured video ${index + 1}`}
                      aspectRatio="177.78%" // 16:9 aspect ratio
                    />
                  )}
                </motion.div>
              ))}
            </div>
            <div className="mt-6 sm:mt-8 flex justify-center px-4">
              <Link
                to="https://www.facebook.com/people/Kidsendo-Dr-Swati-Kanodia/100064115736147"
                className="bg-blue-600 text-white inline-flex items-center space-x-2 font-pt-serif-regular px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg transition duration-300 ease-in-out hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-md hover:shadow-lg"
              >
                <span className="hidden sm:inline">Follow our Kidsendo Page</span>
                <span className="sm:hidden">Facebook</span>
                <FaFacebookSquare className="text-white w-4 h-4 sm:w-5 sm:h-5" />
              </Link>
            </div>
          </motion.section>

          <motion.section
            id="ytvideos"
            className="w-full max-w-7xl mx-auto px-4 py-8 sm:py-12"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-pt-serif-regular mb-6 sm:mb-8 text-center text-gray-800">Educational Videos</h1>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 sm:gap-6">
              <div className="lg:col-span-2 space-y-4 sm:space-y-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <YouTubeVideo videoId={videoIds[0]} />
                </motion.div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                  >
                    <YouTubeVideo videoId={videoIds[1]} />
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <YouTubeVideo videoId={videoIds[2]} />
                  </motion.div>
                </div>
              </div>
              <motion.div
                className="hidden lg:block"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                <YouTubeVideo videoId={videoIds[3]} />
              </motion.div>
            </div>

            <div className="mt-8 sm:mt-10 flex justify-center">
              <Link
                to='https://www.youtube.com/channel/UCMkcxioC_BnVvFbr_CmE2xw'
                className="bg-red-600 text-white inline-flex items-center space-x-2 font-pt-serif-regular px-4 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg transition duration-300 ease-in-out hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 shadow-md hover:shadow-lg"
              >
                <span className="hidden sm:inline">Explore all Videos on YouTube</span>
                <span className="sm:hidden">YouTube Channel</span>
                <FaYoutube className="text-white w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
            </div>
          </motion.section>
        </ScatteredBackground>
      </main>
    </>
  )
}

export default Gallery;
