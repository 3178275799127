import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaEnvelope, FaPhone, FaMapMarkerAlt, FaYoutube, FaInstagram, FaLinkedinIn, FaClock } from 'react-icons/fa';
import { motion } from 'framer-motion';
import AppointmentModal from '../utilities/AppointmentModal';
import ScatteredBackground from '../ui/ScatteredBackground';

const Footer: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <ScatteredBackground lightColor='#f0f4f8' darkColor='#d9e2ec'>
            <footer className="relative text-black py-16">
                <div className="container mx-auto px-4 relative z-10">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <h3 className="text-2xl font-bold mb-6 font-pt-serif-regular text-zinc-800">Dr. Swati Kanodia</h3>
                            <p className="mb-4 text-zinc-600">Consultant Paediatric & Adolescent Endocrinology & Diabetes</p>
                            <p className="mb-4 text-zinc-600">
                                MBBS, MD(Pediatrics)
                            </p>
                            <p className='mb-4 text-zinc-600'>
                                Fellowship - Child and Adolescent Endocrinology
                            </p>

                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/people/Kidsendo-Dr-Swati-Kanodia/100064115736147" className="text-zinc-500 hover:text-zinc-800 transition-colors duration-300">
                                    <FaFacebookF className='text-blue-500' size={20} />
                                </a>
                                <a href="https://www.instagram.com/drswatikanodia/reels/?locale=kk-KZ" className="text-zinc-500 hover:text-zinc-800 transition-colors duration-300">
                                    <FaInstagram className='text-pink-800' size={20} />
                                </a>
                                <a href="https://www.youtube.com/user/swat2718" className="text-zinc-500 hover:text-zinc-800 transition-colors duration-300">
                                    <FaYoutube className='text-red-500' size={20} />
                                </a>
                                <a href='https://in.linkedin.com/in/dr-swati-kanodia-b984b214?trk=public_post_feed-actor-name' className='text-blue-700 hover:text-blue-800 transition-colors duration-300'>
                                    <FaLinkedinIn className='text-blue-600' size={20} />
                                </a>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            <h3 className="text-2xl font-pt-serif-regular mb-6 text-zinc-800">Contact</h3>
                            <p className="flex items-center mb-3 text-zinc-700">
                                <FaMapMarkerAlt className="mr-3 text-zinc-400" />
                                Kidsendo Clinic
                            </p>
                            <p className="flex items-center mb-3 text-zinc-700">
                                <FaPhone className="mr-3 text-zinc-400" />
                                +91-9015550000
                            </p>
                            <p className="flex items-center mb-3 text-zinc-700">
                                <FaEnvelope className="mr-3 text-zinc-400" />
                                dr.swatikanodia@gmail.com
                            </p>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <h3 className="text-2xl font-pt-serif-regular mb-6 text-zinc-800">Schedule</h3>
                            <ul className="space-y-3 text-sm text-zinc-700">
                                <li className="flex items-start">
                                    <FaClock className="mr-2 mt-1 text-zinc-400 flex-shrink-0" />
                                    <span><strong>BL Kapoor Hospital, Pusa Road</strong><br />Mon, Tue, Fri & Sat: 2-4pm</span>
                                </li>
                                <li className="flex items-start">
                                    <FaClock className="mr-2 mt-1 text-zinc-400 flex-shrink-0" />
                                    <span><strong>Rainbow Children's Hospital, Malviya Nagar</strong><br />Wed & Thu: 3-5pm</span>
                                </li>
                                <li className="flex items-start">
                                    <FaClock className="mr-2 mt-1 text-zinc-400 flex-shrink-0" />
                                    <span><strong>Children's Multispeciality Clinic, Vikaspuri</strong><br />Sat: 10am-12pm</span>
                                </li>
                                <li className="flex items-start">
                                    <FaClock className="mr-2 mt-1 text-zinc-400 flex-shrink-0" />
                                    <span><strong>Gauri Hospital, Jawahar Nagar</strong><br />Tue & Fri: 11am-12pm</span>
                                </li>
                                <li className="flex items-start">
                                    <FaClock className="mr-2 mt-1 text-zinc-400 flex-shrink-0" />
                                    <span><strong>Fortis La Femme, GK</strong><br />Fri: 5-7pm</span>
                                </li>
                                <li className="flex items-start">
                                    <FaClock className="mr-2 mt-1 text-zinc-400 flex-shrink-0" />
                                    <span><strong>Aartas Clinishare, Lajpat Nagar</strong><br />Wed: 6-7pm</span>
                                </li>
                                <li className="flex items-start">
                                    <FaClock className="mr-2 mt-1 text-zinc-400 flex-shrink-0" />
                                    <span><strong>Apollo Cradle, Moti Nagar</strong><br />Mon & Thu: 10am-12pm</span>
                                </li>
                            </ul>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <h3 className="text-2xl font-pt-serif-regular mb-6 text-zinc-800">Specialties</h3>
                            <ul className="space-y-2 text-zinc-700">
                                <li className="flex items-center">Gestational Diabetes</li>
                                <li className='flex items-center'>Diabetes</li>
                                <li className='flex items-center'>Growth Hormone Treatment</li>
                                <li className='flex items-center'>Early Puberty (GnRH Analog) Treatment</li>
                                <li>Holistic Obesity Management</li>
                            </ul>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.6 }}
                        >
                            <h3 className="text-2xl font-pt-serif-regular mb-6 text-zinc-800">Quick Links</h3>
                            <ul className="space-y-2">
                                <li><Link onClick={(e) => {
                                    e.preventDefault();
                                    openModal();
                                }} to="#" className="transition-colors">Book Appointment</Link></li>
                                <li><Link to="/about" className="transition-colors">About</Link></li>
                                <li><Link to="/services" className="transition-colors">Services</Link></li>
                                <li><Link to="/contact" className="transition-colors">Contact</Link></li>
                            </ul>
                            <AppointmentModal isOpen={isModalOpen} onClose={closeModal} />
                        </motion.div>
                    </div>

                    <motion.div
                        className="mt-12 pt-8 border-t border-zinc-200 text-center text-zinc-500"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <p className="mb-4">&copy; {new Date().getFullYear()} Dr. Swati Kanodia. All rights reserved.</p>
                        <p className="text-sm">
                            website by: <a href="https://rnahealthtech.com" className="text-zinc-700 hover:underline">RNA HealthTech</a>
                        </p>
                    </motion.div>
                </div>
            </footer>
        </ScatteredBackground>
    );
};

export default Footer;
